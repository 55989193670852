/** @jsx jsx */
import {
  Box,
  Button,
  CloseIcon,
  Container,
  HamburgerIcon,
  Flex,
} from '@bottlebooks/gatsby-theme-base';
import { useState } from 'react';
import { jsx } from 'theme-ui';
import MenuItem from './MenuItem';
import ShoppingCart, {
  ShoppingCartIndicator,
  ShoppingCartText,
} from '../../../../components/ShoppingCart';

/**
 * @param {object} opt
 * @param {object[]} opt.menuItems
 * @returns
 */
export default function MainMenuMobile({ menuItems, ...rest }) {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  return (
    <Container
      sx={{ borderBottom: 1, borderBottomColor: 'primary', paddingBottom: 2 }}
      {...rest}
    >
      <Flex>
        <Flex direction="column" sx={{ width: '100%' }}>
          <Flex>
            <Box sx={{ paddingTop: '16px' }}>
              <Button
                variant="text"
                onClick={() => setMenuIsVisible(!menuIsVisible)}
              >
                {menuIsVisible ? <CloseIcon /> : <HamburgerIcon />}
              </Button>
            </Box>
            <Box sx={{ flexGrow: 1, marginLeft: 3, paddingTop: '14px' }}>
              <img
                height="35"
                alt="Foods Wines from Spain"
                title="Foods Wines from Spain"
                src="https://www.foodswinesfromspain.com/spanishfoodwine/ficheros_estaticos/img/icex_foodsANDwines.png"
              />
            </Box>
            <Flex align="center" sx={{ paddingY: 3 }} {...rest}>
              <ShoppingCartIndicator />
            </Flex>
          </Flex>
          <Flex>
            <ShoppingCartText
              sx={{
                textAlign: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            />
          </Flex>
        </Flex>

        {menuIsVisible && (
          <Container
            sx={{
              position: 'absolute',
              zIndex: 9999,
              left: 0,
              right: 0,
              top: '47px',
              bottom: 0,
              backgroundColor: 'white',
            }}
          >
            <Flex direction="column">
              <Button
                variant="text"
                onClick={() => setMenuIsVisible(!menuIsVisible)}
                sx={{ marginRight: 'auto', marginTop: '12px' }}
              >
                {menuIsVisible ? <CloseIcon /> : <HamburgerIcon />}
              </Button>

              <Flex
                direction="column"
                sx={{
                  flexWrap: 'wrap',
                  alignItems: 'center',
                }}
              >
                {menuItems.map((item, index) => (
                  <MenuItem
                    key={index}
                    target={index < 2 ? '_blank' : ''}
                    {...item}
                  />
                ))}
              </Flex>
            </Flex>
          </Container>
        )}
      </Flex>
    </Container>
  );
}
