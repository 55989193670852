/** @jsx jsx */
import {
  Badge,
  Box,
  Button,
  Link,
  Flex,
  ShoppingCartIcon,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';
import useSnipcart from '../useSnipcart';

/**
 * @returns
 */
export default function ShoppingCart({ ...rest }) {
  return (
    <Flex align="center" sx={{ paddingY: 3 }} {...rest}>
      <ShoppingCartText />
      <ShoppingCartIndicator />
    </Flex>
  );
}

export function ShoppingCartIndicator() {
  const { openCart, itemsInCartCount } = useSnipcart();
  return (
    <Box>
      {itemsInCartCount === 0 ? (
        <ShoppingCartIcon
          size="small"
          sx={{
            marginRight: 2,
            color: 'currentColor',
            height: '30px',
            width: '30px',
          }}
        />
      ) : (
        <Button
          sx={{ border: 0, position: 'relative' }}
          onClick={() => openCart()}
        >
          <ShoppingCartIcon
            sx={{
              marginRight: 2,
              color: 'currentColor',
              height: '30px',
              width: '30px',
            }}
          />
          <Badge
            sx={{
              position: 'absolute',
              paddingY: 1,
              left: '20px',
              top: '-5px',
              backgroundColor: 'primary',
              color: 'white',
              fontSize: '16px',
            }}
          >
            {itemsInCartCount}
          </Badge>
        </Button>
      )}
    </Box>
  );
}

export function ShoppingCartText({ ...rest }) {
  const { openCart, itemsRemainingCount, maxItems } = useSnipcart();

  return (
    <Text variant="small" sx={{ marginLeft: 3 }} {...rest}>
      {itemsRemainingCount > 0 ? (
        itemsRemainingCount === 1 ? (
          'You can add one more product to your cart.'
        ) : (
          `You can add ${itemsRemainingCount} more products to your cart.`
        )
      ) : (
        <Text variant="small" sx={{ marginX: 3 }}>
          You can request up to {maxItems} products.{' '}
          <Link
            onClick={() => openCart()}
            sx={{
              padding: 0,
              margin: 0,
              font: 'inherit',
              fontSize: 'inherit',
              border: 'none',
              backgroundColor: 'transparent',
              color: 'primary',
            }}
          >
            Remove wines
          </Link>{' '}
          to add others.
        </Text>
      )}
    </Text>
  );
}
