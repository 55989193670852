import { SnipcartContext } from 'gatsby-plugin-snipcart-advanced/context';
import React from 'react';

export default function useStore({ maxItems = 12 } = {}) {
  // We use the plugin's state to listen for the ready state.
  // It also has other useful attributes (see below),
  // but all of them are available on the Snipcart store too, so we can as well use that.
  /** @type {{ state?: SnipcartPluginState, changeLanguage: (locale: string) => void }} */
  const { state, changeLanguage } = React.useContext(SnipcartContext);

  // We store the snipcart state locally.
  // It's updated by the listener in useEffect() on every change.
  /** @type {[SnipcartState | undefined, React.Dispatch<React.SetStateAction<SnipcartState>>]} */
  const [snipcartState, setSnipcartState] = React.useState();

  // We use an effect to listen on the snipcart state client-side only.
  React.useEffect(() => {
    // @ts-ignore TS doesn't know this.
    const { Snipcart } = window;
    if (Snipcart && state?.ready) {
      // Update the state on each change.
      const listenSnipcart = () => {
        setSnipcartState(Snipcart.store.getState());
      };
      // Set the state initially.
      listenSnipcart();
      // Listen to state changes.
      const unsubscribe = Snipcart.store.subscribe(listenSnipcart);
      // Return the unsubscribe function.
      return unsubscribe;
    }
  }, [state?.ready]);

  const cart = snipcartState?.cart;
  const itemsInCartCount = cart?.items?.count || 0;
  return {
    itemsInCartCount,
    canAddMoreProducts: itemsInCartCount < maxItems,
    itemsRemainingCount: maxItems - itemsInCartCount,
    openCart: () => {
      // @ts-ignore TS doesn't know this.
      const { Snipcart } = window;
      if (Snipcart && state?.ready) {
        Snipcart.api.theme.cart.open();
      }
    }, // TODO
    isItemInCart: (product) =>
      Boolean(cart?.items?.items?.find(({ id }) => id === product?.productId)),
    changeLanguage,
    maxItems,
  };
}

/**
 * The state from the Snipcart plugin. It's just a fraction of the Snipcart state.
 * @see https://github.com/ipatate/gatsby-plugin-snipcart-advanced/blob/master/src/store/index.js
 * @typedef {object} SnipcartPluginState
 * @property {boolean} ready
 * @property {'SignedOut' | ''} userStatus
 * @property {number} cartQuantity
 * @property {number} cartTotal
 * @property {number} cartSubtotal
 */

/**
 * @see https://docs.snipcart.com/v3/sdk/reference#core-state-SnipcartState
 * @typedef {object} SnipcartState
 * @property {object} [cart]
 */
