/** @jsxRuntime classic */
/** @jsx jsx */
import { Box } from '@bottlebooks/gatsby-theme-base';
import { css, Global } from '@emotion/core';
import { jsx } from 'theme-ui';
import ConfirmationDialog from '../../../../components/SpainTheme/ConfirmationDialog';
import PreHeader from '../../../../components/SpainTheme/PreHeader';
import TopBar from '../../../../components/SpainTheme/TopBar';
import MainMenu from './MainMenu';

export default function Header(props) {
  return (
    <Box as="header" sx={{ backgroundColor: 'white' }} {...props}>
      <Global
        styles={css`
          .snipcart-item-quantity {
            display: none !important;
          }
        `}
      />
      <TopBar />
      <ConfirmationDialog />
      <PreHeader />
      <MainMenu locale={props.locale} />
    </Box>
  );
}
