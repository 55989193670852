/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import '@reach/dialog/styles.css';
import { graphql, useStaticQuery } from 'gatsby';
import { jsx } from 'theme-ui';
import createPersistedState from 'use-persisted-state';
import ConfirmationDialogExporters from './ConfirmationDialogExporters';
import ConfirmationDialogImporters from './ConfirmationDialogImporters';
const useConfirmationState = createPersistedState('bottlebooks:confirmation');

export default function ConfirmationDialog({ ...rest }) {
  const [confirmationState, setConfirmationState] = useConfirmationState({
    accepted: false,
  });
  const {
    event: { eventId },
  } = useStaticQuery(query);

  function onDismiss() {
    setConfirmationState({ accepted: true });
  }

  if (confirmationState.accepted) return null;

  // if (eventId === '605c485c72c908691ddde7c5')
  return (
    <ConfirmationDialogExporters
      onDismiss={onDismiss}
      eventId={eventId}
      {...rest}
    />
  );

  if (eventId === '605c3873a620506917e0c488')
    return (
      <ConfirmationDialogImporters
        onDismiss={onDismiss}
        eventId={eventId}
        {...rest}
      />
    );

  return null;
}

const query = graphql`
  query HeaderQuery {
    event {
      eventId
    }
  }
`;
