/** @jsx jsx */
import { Box, Container, Flex } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';
import MenuItem from './MenuItem';

/**
 * @param {object} opt
 * @param {object[]} opt.menuItems
 * @returns
 */
export default function MainMenuDesktop({ menuItems, ...rest }) {
  return (
    <Container {...rest}>
      <Box sx={{ marginLeft: 'auto' }}>
        <Flex sx={{ flexWrap: 'wrap' }}>
          {menuItems.map((item, index) => (
            <MenuItem
              key={index}
              {...item}
              sx={{ width: ['48%', '48%', 'initial', 'initial'] }}
            />
          ))}
        </Flex>
      </Box>
    </Container>
  );
}
