/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  Title,
} from '@bottlebooks/gatsby-theme-base';
import { Global } from '@emotion/core';
import Dialog from '@reach/dialog';
import '@reach/dialog/styles.css';
import { jsx } from 'theme-ui';

export default function ConfirmationDialogImporters({ onDismiss, ...rest }) {
  return (
    // @ts-ignore sx is unknown, but valid.
    <Dialog
      isOpen
      aria-label="Confirm agreement"
      sx={{ width: ['100vw', '100vw', '75vw', 768] }}
    >
      <Global
        styles={(theme) => ({
          '[data-reach-dialog-overlay]': { zIndex: theme.zIndices?.modal },
        })}
      />
      <Flex direction="column">
        {/* Dummy link to prevent focusing on mobile. */}
        <Link
          href="https://www.foodswinesfromspain.com/spanishfoodwine/global/upcoming-events/trade-shows-detail-four/annual-tasting-2021.html"
          target="_blank"
        >
          <Text variant="smallest" tabindex={0}>
            Wines from Spain UK 2021
          </Text>
        </Link>
        <img
          alt="Foods Wines from Spain"
          title="Foods Wines from Spain"
          src="https://www.foodswinesfromspain.com/spanishfoodwine/ficheros_estaticos/img/icex_foodsANDwines.png"
          // @ts-ignore sx is unknown, but valid.
          sx={{
            marginBottom: 4,
            width: ['200px', null, '300px'],
          }}
        />
        <Title sx={{ marginBottom: 4 }} tabindex={0}>
          Exporters Digital Tasting
        </Title>
        <Paragraph>
          Welcome to the 2021 Wines from Spain Exporters Digital Tasting.
        </Paragraph>
        <Paragraph>
          The deadline to order sample packs for this tasting was Thursday 13th
          May. This event is now closed and we are no longer able to accomodate
          sample requests.
        </Paragraph>
        <Paragraph>
          Please go to{' '}
          <Link href="https://wfs.bottlebooks.me/explore/">
            https://wfs.bottlebooks.me/explore/
          </Link>{' '}
          to visit the full Wines from Spain Annual Tasting catalogue. You can
          use the ‘book a call’ button or the contact details listed on the site
          to request samples from producers and importers directly. A ‘physical’
          tasting event is also planned for 22 &amp; 23 June 2021.
        </Paragraph>
        <Paragraph>
          For further information, please email:{' '}
          <Link href="mailto:winesfromspain@otaria.co.uk" target="_blank">
            winesfromspain@otaria.co.uk
          </Link>
          .
        </Paragraph>
        <Button
          sx={{ marginLeft: 'auto' }}
          onClick={onDismiss}
          variant="primary"
        >
          Close
        </Button>
      </Flex>
    </Dialog>
  );
}

function Paragraph({ children }) {
  return (
    <Box sx={{ marginBottom: 3, width: '100%' }}>
      <Text>{children}</Text>
    </Box>
  );
}
