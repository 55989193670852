/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Container, Flex } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';

export default function TopBar(props) {
  return (
    <Box sx={{ variant: 'topBar' }} {...props}>
      <Flex
        as={Container}
        justify="flex-end"
        align="center"
        sx={{ variant: 'topBar', marginX: 'auto' }}
      ></Flex>
    </Box>
  );
}
