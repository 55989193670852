/** @jsxRuntime classic */
/** @jsx jsx */
import { Box, Flex, Row } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';
import ShoppingCart from '../ShoppingCart';

export default function PreHeader() {
  return (
    <Box
      sx={{
        display: ['none', null, 'flex'],
        borderBottom: 1,
        borderBottomColor: 'primary',
      }}
    >
      <Box
        sx={{
          maxWidth: '960px',
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <img
              width="221"
              height="45"
              alt="Foods Wines from Spain"
              title="Foods Wines from Spain"
              src="https://www.foodswinesfromspain.com/spanishfoodwine/ficheros_estaticos/img/icex_foodsANDwines.png"
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              height="33"
              width="50"
              alt="FEDER"
              title="FEDER"
              src="https://www.foodswinesfromspain.com/spanishfoodwine/ficheros_estaticos/img/feder.png"
            />
          </Box>

          <ShoppingCart />
        </Flex>
      </Box>
    </Box>
  );
}
